<template>

</template>

<script>
import { MICROSOFT_LOGOUT } from "@/core/services/store/auth.module";

export default {
  name: "Logout",
  mounted() {
    this.$store.dispatch(MICROSOFT_LOGOUT, { url: `${window.__env.api}/login` })
        .then((value) => {
          window.location = value.url;
        })
        .catch(() => {});
  }
}
</script>

<style scoped>

</style>